import shrinkTextRef from '@/refs/shrinkTextOnOverflow'
import { useCallback } from 'react'

const useShrinkText = (options: Parameters<typeof shrinkTextRef>[1] = {}) => {
    const ref = useCallback(
        (contentEl: HTMLElement | null) => {
            return shrinkTextRef(contentEl, options)
        },
        [...Object.values(options)]
    )
    return ref
}

export default useShrinkText
