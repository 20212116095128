const shrinkTextRef = (
    contentEl: HTMLElement | null,
    { horizontal = false, debug, calculateByParentHeight }: { horizontal?: boolean; debug?: string; calculateByParentHeight?: boolean } = {}
) => {
    const MIN_FONT_SIZE = 10

    if (!contentEl) return

    let fontSize = 100

    /** Returns true if the text overflows past the window height. */
    const overflow = () => {
        const parentSize = horizontal
            ? contentEl.parentElement?.getBoundingClientRect().width
            : contentEl.parentElement?.getBoundingClientRect().height
        if (calculateByParentHeight && parentSize && (horizontal ? contentEl.scrollWidth : contentEl.scrollHeight) <= parentSize) {
            return false
        }
        const hasOverflow = horizontal ? contentEl.scrollWidth > contentEl.clientWidth : contentEl.scrollHeight > contentEl.clientHeight
        if (debug) console.log('hasOverflow', debug, contentEl.scrollHeight, contentEl.clientHeight)
        return hasOverflow
    }

    /** Decreases the font size of the element. */
    const shrinkFontSize = (el: HTMLElement) => (el.style.fontSize = --fontSize + '%') // eslint-disable-line no-return-assign

    if (fontSize) {
        while (overflow() && fontSize >= MIN_FONT_SIZE) {
            shrinkFontSize(contentEl)
        }
    }
}

export default shrinkTextRef
